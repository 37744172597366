<!-- Version: 1.0 -->
<template>
  <div :key="'fp-tabs-' + index + '-container'">
    <b-tabs
      class="eh-tabs"
      active-nav-item-class="eh-active-tab"
      fill
      :lazy="lazy"
      v-model="tabIndex"
    >
      <b-tab
        v-for="(tab, tabsIndex) in tabs"
        :key="'fp-tab-' + tabsIndex"
        title-item-class="eh-tabs-item"
        title-link-class="eh-tabs-link"
        class="eh-tab-content"
        :disabled="disabledTabs && !tab[noneDisabledKey]"
      >
        <template #title>
          <slot name="title" v-bind="tab"></slot>
        </template>
        <slot name="content" v-bind="tab"></slot>
      </b-tab>
      <slot></slot>
    </b-tabs>
  </div>
</template>
<script>
export default {
  name: "Tabs",
  model: {
    prop: "index",
    event: "change",
  },
  props: {
    tabs: Array,
    lazy: {
      type: Boolean,
      default: true,
    },
    disabledTabs: Boolean,
    noneDisabledKey: String,
    index: Number,
  },
  data() {
    return {
      tabIndex: this.index,
    };
  },
  watch: {
    tabIndex(input) {
      this.$emit("change", input);
    },
    index(input) {
      this.tabIndex = input;
    },
  },
};
</script>
